import { MachineLineItem, MachinePaymentMode } from 'ui/src/types';
import * as styles from './Item.module.scss';
import { ButtonResult, ButtonType, Design, FinancePopUpBody, Mode, PopupSize, QuantitySelector, popup, updateMachineCart, updateMachineCartItem } from 'ui';
import cn from 'classnames';
import { UpdateFinanceOptions } from 'finance-button/src/types';

export const Item = ({ productItem, readonly }: { productItem: MachineLineItem, readonly?: boolean }) => {
    const translations = window.app.preloadState.translation;

    const showFinancingPopUp = async () => {
        let financeOptions: UpdateFinanceOptions | null = null;
        const financingPopUpData = window.app.preloadState.financingPopUp;
        const popUpResult = await popup(
            translations["financeOptions.header"],
            <FinancePopUpBody serialNumber={productItem.code} readonly={readonly}   />,
            [],
            PopupSize.ExtraVertical,
            undefined,
            {
                content: <div className={styles.info}>
                    <div>
                        <span>{translations["financeOptions.email"]}: {financingPopUpData.financingPopUpEmailAddress}</span>
                    </div>
                    <div>
                        <span>{translations["financeOptions.phone"]}: {financingPopUpData.financingPopUpPhoneNumber}</span>
                    </div>
                </div>,
                reverse: true
            }
        );

        if (popUpResult === ButtonResult.Ok) {

            const updateResponse = await fetch(`/api/machinesales/cart/items/update-finance-options`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify(financeOptions),
                method: "POST"
            });

            if (!updateResponse.ok) {
                console.error(await updateResponse.text());
            }
            await updateMachineCart();
        } else if (popUpResult === ButtonResult.No) {
            const updateResponse = await fetch(`/api/machinesales/cart/items/remove-finance-options`, {
                headers: {
                    "Content-Type": "application/json",
                    "Swecon-Current-Language": window.app.preloadState.currentLanguage
                },
                body: JSON.stringify({ code: productItem.code }),
                method: "DELETE"
            });

            if (!updateResponse.ok) {
                console.error(await updateResponse.text());
            }
            await updateMachineCart();
        }
    }

    const removeItem = ( event: any, code: string) => {
        updateMachineCartItem(code, 0);
        event.preventDefault()
    }

    const showPopUp = (event: any) => {
        showFinancingPopUp();
        event.preventDefault();
    }

    return <a href={productItem.url} className={styles.item}>
        <div className={styles.imageContainer}>
            <img src={productItem.imageUrl ?? ""} />
        </div>
        <div className={styles.statsContainer}>
            <div className={styles.topStatsContainer}>
                <p className={styles.title}>{productItem.displayName}</p>
                <p className={styles.delivery}>{translations["machineSalesCheckout.deliveryApprox"]} {productItem.machineDeliveryInfo}</p>
            </div>
            <div>
                <div className={styles.selector}>
                    <p>
                        <span className={"d-none d-md-block"}>{translations["machineSalesCheckout.quantity"]}:</span>
                    </p>
                    <QuantitySelector
                        onChange={(q) => updateMachineCartItem(productItem.code, q)}
                        onRemove={(f) => updateMachineCartItem(productItem.code, 0).then(() => f())}
                        initialValue={productItem.quantity}
                        max={999}
                        min={1}
                        disabled={readonly}
                        mode={Mode.Buy}
                        step={1}
                        requestQuotationByEmail={""}
                        contactSupportLink={""}
                        showRemanufacturePopUp={undefined}
                        forceMinValue={true}
                        design={Design.New}
                    />
                </div>
            </div>
            <div className={styles.bottomStatsContainer}>
                {productItem.paymentMode === MachinePaymentMode.Direct ? <>
                    <p>{translations["machineSalesCheckout.fullAmountTotal"]}</p>
                    <p className={styles.price}>{productItem.price}</p>
                </> : <>
                    <p>{translations["machineSalesCheckout.totalMonthlyCost"]}</p>
                    <p className={styles.price}>{productItem.financeOptions.monthlyPaymentWithCurrency}</p>
                </>}
                <span onClick={(event) => showPopUp(event)} className={styles.showFinanceOptions}>{translations["machineSalesCheckout.linkToShowFinancingPopup"]}</span>
            </div>
        </div>
        <div className={cn(styles.trashContainer)}>
            {!readonly && <span className={styles.removeItemIcon} onClick={(event) => removeItem(event, productItem.code)}></span>}
        </div>
    </a>;
}